import React, { PureComponent } from 'react';
import { message, Form, Select, Input } from 'antd';
import { Button, ProductDialog, EditTable, Layout, InputNumber, Amount, Dialog } from '@/components';
import { FormInstance } from 'antd/lib/form';
import { hideLoading, showLoading } from '@/components/message/message';
import BigNumber from 'bignumber.js';
import WebApi from '@/web/api';

export default class PurchaseOrderCreatePage extends PureComponent<RouterPropsType> {
  private productSelectModal: ProductDialog | null = null
  private form: FormInstance | null = null

  state = {
    suppliers: [],
    dataSource: [],
    btnDisabled: false,
    loadingSupplier: true,
  };

  componentDidMount() {
    this.findSuppliers();
  }

  findSuppliers = () => {
    WebApi.supplier_list()
    .then((data: any) => {
      this.setState({
        suppliers: data,
        loadingSupplier: false,
      });
    })
  }

  render() {

    const {dataSource, btnDisabled, suppliers, loadingSupplier} = this.state;

    return (<Layout.Page>
      <Layout flex1 flexColumn overHidden>
        <Layout.Title>订单信息</Layout.Title>
        <Form ref={r => this.form = r} style={{width: 500, marginBottom: 30}}>
          <Form.Item label="供应商" name="supplierId" labelCol={{span: 4}} style={{width: 450}} rules={[{
            required: true,
            message: '请选择供应商',
          }]}>
            <Select showSearch loading={loadingSupplier} placeholder="选择供应商">
              {
                suppliers.map((item: any) => {
                  return <Select.Option value={item.supplierId} key={item.supplierId}>{item.supplierName}</Select.Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name="remark" label="备注" labelCol={{span: 2}} style={{width: 900}}>
            <Input placeholder="填写备注" className="underline"/>
          </Form.Item>
        </Form>
        

        <Layout.Title icon="icon-form-shangpin">商品明细</Layout.Title>
        <EditTable
          flex1
          dataSource={dataSource}
          onAdd={() => {
            this.productSelectModal?.setState({
              visible: true
            });
          }}
          onMinus={(record, index) => {
            const next = [...dataSource];
            next.splice(index, 1);
            this.setState({
              dataSource: next
            });
          }}
          columns={[
            {
              title: '主图',
              dataIndex: 'imageLogo',
              width: 60,
            },
            {
              title: '商品名称',
              dataIndex: 'productName',
              width: 200,
            },
            {
              title: '规格',
              dataIndex: 'skuName',
              width: 300,
              render: (v, row) => {
                return <Select placeholder="选择规格" style={{width: '100%'}} value={row.skuId || ''} onChange={(v, option: any) => {
                  // 检查一下规格是否已经有了
                  const sku = dataSource.find((item: any) => item.skuId === v);
                  if (sku) {
                    message.error('规格已存在，不能重复！');
                  } else {
                    row.skuId = v;
                    row.skuName = option.children;
                    this.setState({
                      dataSource: [...dataSource],
                    });
                  }
                }}>
                  {
                    row.skuList.map(item => {
                      return <Select.Option value={item.skuId} key={item.skuId}>{item.skuName}</Select.Option>
                    })
                  }
                </Select>
              },
            },
            {
              title: '进价',
              dataIndex: 'price',
              width: 150,
              align: 'center',
              render: (text, record, index) => {
                if (!record.skuId) return <div>请先选择规格</div>
                return <InputNumber.Price style={{width: 100}} selected min={0} defaultValue={record.price} value={record.price || ''} onChange={(value) => {
                  record.price = value;
                  this.setState({
                    dataSource: [...dataSource],
                  });
                }}/>;
              },
            },
            {
              title: '数量',
              dataIndex: 'amount',
              width: 150,
              align: 'center',
              render: (text, record, index) => {
                if (!record.skuId) return <div>请先选择规格</div>
                return <InputNumber.Count style={{width: 100}} selected min={1} defaultValue={record.count}value={record.count || ''}   onChange={(value) => {
                  record.count = value;
                  this.setState({
                    dataSource: [...dataSource],
                  });
                }}/>
              },
            },
            {
              title: '备注',
              dataIndex: 'remark',
              titleAlign: 'left',
              width: 'auto',
              render: (text, record, index) => {
                if (!record.productId) return null;
                return <div style={{minWidth: 150}}>
                  <Input style={{width: '100%'}} value={record.remark} onChange={(e) => {
                    record.remark = e.target.value;
                    this.setState({
                      dataSource: [...dataSource],
                    });
                  }}/> 
                </div>
              },
            },
          ]}
          scroll={{y: '100%', x: true}}
          rowKey={(row: any) => row.skuId || row.id}
        />
        <div style={{textAlign: 'right', background: '#fff'}}>
          <strong style={{marginBottom: 2}}>金额合计：</strong>
          <Amount>{this.getTotalAmount()}</Amount>
        </div>
      </Layout>

      <Layout.FootBar>
        <Button type="primary" disabled={btnDisabled} onClick={() => {
          this.clickSave(this.save);
        }}>保存</Button>
        <Button style={{marginLeft: 24}} disabled={btnDisabled} onClick={() => {
          this.clickSave(this.saveAndCreate);
        }}>保存并新增</Button>
      </Layout.FootBar>
      <ProductDialog
        ref={r => this.productSelectModal = r}
        onOk={this.onSelectProductOk}/>
    </Layout.Page>)
  }

  private getTotalAmount = () => {
    return this.state.dataSource.reduce((pre: any, cur: any) => {
      return new BigNumber(cur.count || '0').multipliedBy(cur.skuId ? cur.price || '0' : 0).plus(pre).toFixed(2);
    }, '0');
  }

  onSelectProductOk = data => {
    const nextDataSource = data.map(item => {
      return {
        ...item,
        count: 1,
        price: undefined,
      };
    });

    this.setState((state: any) => {
      return {
        visible: false,
        dataSource: state.dataSource.concat(nextDataSource),
      }
    });
  };

  clickSave = async (callback) => {
    let values;
    try {
      values = await this.form?.validateFields();
    } catch (e) {
      console.error(e);
      return;
    }
    if (this.state.dataSource.length === 0) {
      message.error('请选择商品！');
      return;
    }

    let item: any;
    for (item of this.state.dataSource) {
      if (!item.skuId) {
        message.error(`商品 “${item.productName}” 规格不能为空`);
        return;
      }
    }

    const priceZero = this.state.dataSource.find((item: any) => !item.price);
    if (priceZero) {
      Dialog.confirm({
        title: '温馨提示',
        content: '检测到有价格为 0 的商品，是否继续保存？',
        onOk: () => {
          this.submit(values, callback);
        },
      });
      return;
    }
    this.submit(values, callback);
  }

  private submit = (values, callback) => {
    const products = this.state.dataSource.map((item: any) => {
      return {
        productName: item.productName,
        productId: item.productId,
        skuId: item.skuId,
        skuName: item.skuName,
        count: Number(item.count),
        price: item.price || '0.00',
      };
    });

    this.setState({
      btnDisabled: true,
    });

    showLoading(1);
    WebApi.purchase_order_create({
      supplierId: values.supplierId,
      detailList: products,
      remark: values.remark,
    })
    .then(() => {
      // this.props.history.replace(routerMap['success.create'].path);
      showSuccess.save();
      callback();
    })
    .catch(e => {
      showError('出错了: ' + e.message);
      this.setState({
        btnDisabled: false,
      });
    })
    .finally(() => {
      hideLoading();
    });
  }

  saveAndCreate = () => {
    showSuccess.save();
    this.form?.resetFields();
    this.setState({
      dataSource: [],
    });
  }

  save = () => {
    this.props.history.close();
  }
}
